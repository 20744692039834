import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';

import 'aos/dist/aos.css';
import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
  name: "news",
  mixins: [dynamicHead],
  components:{
  },
  data(){
    return{
      page: 1,
      fragmentedNews: [],
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'news', title: this.$t('news'), slug: ''}
      ],
    }
  },
  watch: {
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.news
      }
    },
    '$route.query.page': {
      handler(newVal){
        if(newVal){
          console.log('sad');
          this.loadMore()
        }
      }
    }
  },
  created() {
    this.page = this.$route.query.page ? this.$route.query.page : 1
    // this.resetSkip()
    this.fetchNews(this.page).then(()=>{
      if(this.news.length){
        this.fragmentedNews = _.chunk(this.news, 4)
      }
    }).catch(error=>{
      console.log(error)
    })
    this.fetchMeta(this.$route.name).then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
  },
  computed:{
    ...mapGetters({
      news: 'news/news',
      topNews: 'news/topNews',
      showPagination: 'news/showPagination',
      limit: 'news/limit',
      meta: 'meta/meta',
    })
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });

  },
  methods:{
    ...mapActions({
      fetchNews: 'news/GET_NEWS',
      fetchMeta: 'meta/GET_META',
    }),
    ...mapMutations({
      changeSkip: 'news/CHANGE_PAGE_SKIP',
      resetSkip: 'news/RESET_SKIP'
    }),
    loadMore(){
      this.page++
      // this.changeSkip()
      this.fetchNews(this.page).then(()=>{
        if(this.news.length){
          this.fragmentedNews = _.chunk(this.news, 4)
        }
      }).catch(error=>{
        console.log(error)
      })
    },
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    }
  }
}